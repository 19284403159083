@import 'reboot';
@import 'base';

// Dependencies
@import '~bootstrap/scss/bootstrap-grid';
@import "./utilities";
@import "~bootstrap/scss/utilities/api";

// Atoms
@import './atoms/btn';
@import './atoms/checkbox';
@import './atoms/content';
@import './atoms/font';
@import './atoms/heading';
@import './atoms/img';
@import './atoms/input';
@import './atoms/link';
@import './atoms/price';
@import './atoms/select';
@import './atoms/text';
@import './atoms/wishlist-trigger';

// Components

// Organisms
@import './organisms/footer';
@import './organisms/header';
@import './organisms/searchbar';
@import './organisms/section';

body {
    // sass-lint:disable no-vendor-prefixes
    -webkit-font-smoothing: antialiased;
    background: $secondary-color-lighter;
    color: $black;
    font-family: $font-family;

    // Add Bootstrap breakpoint to body
    &::after {
        content: 'xs';
        display: none;
    }

    @each $size in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($size) {
            &::after {
                content: '#{$size}';
            }
        }
    }

    input[name="verify"] {
        display: none;
    }
}
