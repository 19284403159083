.text {
    color: $text-color;
    font-family: $font-family;
    font-size: map-get($fs, 200);
    font-weight: map-get($fw, 400);
    line-height: 1.7;

    &--primary {
        color: $primary-color;
    }

    &--secondary {
        color: $secondary-color;
    }

    &--success {
        color: $success-color;
    }

    &--light {
        color: $text-color-light;
    }

    &--inverted {
        color: $white;
    }

    strong {
        font-weight: map-get($fw, 600);
    }
}
