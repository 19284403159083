@mixin overlay($z-index: overlay) {
    @include transition;
    @include z-index($z-index);
    cursor: pointer;
    position: fixed;
    inset: 0;
    background: rgba($black, .75);
    opacity: 0;
    visibility: hidden;
}

@mixin overlayVisible() {
    opacity: 1;
    visibility: visible;
}
