.searchbar {
    position: relative;
    display: flex;
    width: rem(370);
    height: 3rem;
    padding: .25rem;
    border: 1px solid $secondary-color-light;
    border-radius: 1.5rem;
    font-family: $font-family;
    font-size: map-get($fs, 300);
    font-weight: map-get($fw, 400);

    &__zipcode {
        width: rem(110);
        padding: 0 1.25rem 0 1rem;
        color: $text-color;
        border: none;
        border-right: 1px solid $secondary-color-light;
        border-radius: 1.5rem 0 0 1.5rem !important;
        box-shadow: none;
        outline: none;
        font: inherit;
    }

    &__distances {
        width: rem(150);
        margin: -.25rem 1.25rem -.25rem 0;
        padding: .75rem 0 .75rem 1rem;
        background: $white;
        color: $text-color;
        border: none;
        box-shadow: none;
        outline: none;
        font: inherit;
    }

    &__submit {
        padding: rem(11);
        margin-left: auto;
    }
}
