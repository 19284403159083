// $d: diameter, $r: radius
@mixin loading($d: 1rem, $background: $black, $forground: $white) {
    $r: calc(#{$d} / 2);

    @include transition;
    position: absolute;
    inset: 50% auto auto 50%;
    width: $d;
    height: $d;
    color: $forground;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px;
    transform: translateZ(0) translate(-50%, -50%);

    &:before, &:after {
        @include transition;
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(#{$r} + 2px);
        height: calc(#{$d} + 4px);
        background: $background;
        border-radius: calc(#{$d} + 2px) 0 0 calc(#{$d} + 2px);
        transform-origin: calc(#{$r} + 2px) calc(#{$r} + 2px);
        animation: btnLoadingAnimation 2s infinite ease 1.5s;
    }

    &:after {
        left: calc(#{$r} - 1px);
        border-radius: 0 calc(#{$d} + 2px) calc(#{$d} + 2px) 0;
        transform-origin: 1px calc(#{$r} + 1px);
        animation: btnLoadingAnimation 2s infinite ease;
    }

    @keyframes btnLoadingAnimation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

@mixin loadingDone($d: 1rem, $forground: $white) {
    $r: calc(#{$d} / 2);

    width: $r;
    height: $d;
    border-bottom: 2px solid $forground;
    border-right: 2px solid $forground;
    border-radius: 0;
    box-shadow: none;
    transform: translate(-50%, -60%) rotate(45deg);

    &:before, &:after {
        border-radius: 0;
        opacity: 0;
    }
}
