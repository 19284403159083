.heading {
    font-family: $font-family;
    font-size: map-get($fs, 500);
    font-weight: map-get($fw, 700);
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
        font-size: map-get($fs, 700);
        font-weight: map-get($fw, 800);
    }

    a &, &--secondary {
        color: $secondary-color;
    }

    &--inverted{
        color: $white;
    }

    &--300 {
        font-size: map-get($fs, 300);
        font-weight: map-get($fw, 700);
    }

    &--400 {
        font-size: map-get($fs, 400);
        font-weight: map-get($fw, 700);
    }
}
