.content {
    @extend .text;

    h1, h2, h3 {
        @extend .heading;
        margin-bottom: .75rem;
    }

    h2, h3 {
        font-size: map-get($fs, 500);
        font-weight: map-get($fw, 700);
    }

    p {
        @extend .text;
        margin-bottom: 1rem;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        @extend .link;
        font-weight: map-get($fw, 400) !important;
        text-decoration: underline !important;
    }

    ul, ol {
        padding-left: 1rem;
        margin-bottom: 1.5rem;

        li {
            @extend .text;
            line-height: 2;

            &::before {
                content: "•";
                display: inline-block;
                vertical-align: -1px;
                margin: 0 .5rem 0 -1rem;
                color: $secondary-color;
                font-size: map-get($fs, 500);
            }
        }
    }

    ol li {
        list-style: decimal;

        > ol > li, > ul > li {
            list-style: lower-alpha;
        }
    }

    table {
        @extend .text;
    }
}
