.input {
    position: relative;
    display: block;

    &__label {
        display: block;
        margin-bottom: .25rem;
        font-size: map-get($fs, 200);
        font-weight: map-get($fw, 600);
    }

    &__required {
        color: $primary-color;
    }

    &__description {
        color: $text-color-light;
        font-size: map-get($fs, 100);
        font-weight: map-get($fw, 600);
    }

    &__group {
        @include transition;
        cursor: text;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 2.25rem;
        padding: rem(11) rem(9);
        background: $white;
        border: 1px solid $secondary-color-light;
        border-radius: .25rem;

        @include media-breakpoint-up(lg) {
            height: 2.5rem;
        }

        .input--textarea & {
            height: initial;
        }
    }

    &__field {
        flex-basis: 0;
        flex-grow: 1;
        width: 100%;
        margin: 0;
        padding: 0;
        color: $text-color;
        background: transparent;
        border: none;
        box-shadow: none;
        outline: none;
        font-family: $font-family;
        font-size: map-get($fs, 300);
        font-weight: map-get($fw, 400);
        text-overflow: ellipsis;

        &::placeholder {
            color: $placeholder-color;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
        }

        .input--textarea & {
            resize: vertical;
            line-height: 1.25;
            min-height: rem(75);
        }

        &[type="number"] {
            appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &[type="radio"] {
            display: none;

            &:checked {
                ~ .input__radio-field {
                    border-width: 5px;
                }
            }
        }
    }

    &__radio {
        cursor: pointer;
        display: inline-grid;
        grid-template-columns: auto minmax(0, 1fr);
        grid-gap: .5rem;
        align-items: center;
        height: 2.5rem;
        margin-right: 1rem;
    }

    &__radio-field {
        @include transition;
        width: 1rem;
        height: 1rem;
        border: 2px solid $primary-color;
        border-radius: 50%;
    }
}
