.select {
    cursor: pointer;
    position: relative;
    display: block;
    background: $white;
    border: 1px solid $secondary-color-light;
    border-radius: .25rem;

    &__field {
        appearance: none;
        padding: .5rem 2rem .5rem .5rem;
        background: transparent;
        border: none;
        outline: none;
    }

    &__chevron {
        pointer-events: none;
        position: absolute;
        top: 50%;
        right: .5rem;
        transform: translateY(-50%);

        svg {
            display: block;
            width: .5rem;
            height: .25rem;
        }
    }
}
