.btn {
    @include transition;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: rem(9) 1rem;
    background: $primary-color;
    color: $white;
    border-radius: 1.25rem;
    font-size: map-get($fs, 200);
    font-weight: map-get($fw, 700);
    line-height: 1.125rem;

    @include media-breakpoint-up(lg) {
        padding: rem(11) 2rem;
    }

    &:hover {
        background: darken($primary-color, 10%);
    }

    &--secondary {
        background: $secondary-color;

        &.btn--arrow {
            &:after {
                border-left-color: $secondary-color;
            }
        }

        &:hover {
            background: darken($secondary-color, 10%);

            &.btn--arrow {
                &:after {
                    border-left-color: darken($secondary-color, 10%);
                }
            }
        }
    }

    &--arrow {
        padding-right: .25rem;
        padding-left: .75rem;
        border-radius: 0;
        box-shadow: 0 0 .5rem rgba($black, .25);

        &:after {
            @include transition();
            content: '';
            position: absolute;
            top: 0;
            right: -1rem;
            border: solid transparent;
            border-left-color: $primary-color;
            border-width: rem(18.5) 0 rem(18.5) 1rem;

            @include media-breakpoint-up(lg) {
                border-width: 1.25rem 0 1.25rem 1rem;
            }
        }

        &:hover {
            &:after {
                border-left-color: darken($primary-color, 10%);
            }
        }

        &.btn--arrow-start {
            padding-right: .75rem;
            padding-left: .25rem;

            &:after {
                right: initial;
                left: -1rem;
                border-right-color: $primary-color;
                border-left-color: initial;
                border-width: rem(18.5) 1rem rem(18.5) 0;

                @include media-breakpoint-up(lg) {
                    border-width: 1.25rem 1rem 1.25rem 0;
                }
            }

            &.btn--secondary {
                &:after {
                    border-right-color: $secondary-color;
                }
            }

            &:hover {
                &:after {
                    border-right-color: darken($primary-color, 10%);
                }

                &.btn--secondary:after {
                    border-right-color: darken($secondary-color, 10%);
                }
            }
        }
    }

    &--outline {
        padding: .5rem 1rem;
        background: transparent;
        color: $primary-color;
        border: 1px solid $primary-color;

        @include media-breakpoint-up(lg) {
            padding: rem(10) 2rem;
        }

        &.btn--secondary {
            color: $secondary-color;
            border-color: $secondary-color;
        }

        &:hover {
            color: $white;

            svg path {
                fill: $white;
            }
        }
    }

    &--square {
        border-radius: 0;
    }

    &--input {
        margin-top: 1.25rem;
        margin-left: -.25rem;
        border-radius: 0 .25rem .25rem 0;
    }

    &--full {
        display: flex;
        width: 100%;

        &.btn--arrow {
            width: calc(100% - 1rem);
        }
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: .7;
    }

    &__loading {
        @include loading(1.5rem, $primary-color, $white);

        .btn--secondary & {
            @include loading(1.5rem, $secondary-color, $white);
        }

        .btn:not([data-loading]) & {
            display: none;
        }

        .btn[data-loading] & ~ * {
            opacity: 0;
        }

        .btn[data-loading-done] & {
            @include loadingDone(1.5rem, $white);
        }
    }
}
