$z-index-elements: (
    header,
    overlay,
    filters,
    gallery
);

@mixin z-index($element) {
    z-index: index($z-index-elements, $element) + 10;
}
