.wishlist-trigger {
    @include transition;
    cursor: pointer;
    position: relative;

    path {
        + path {
            display: none;
        }
    }

    &[data-in] {
        path {
            display: none;

            + path {
                display: initial;
            }
        }
    }

    &:not([data-in]) {
        opacity: .25;
    }
}
