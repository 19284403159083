@import './_utils/px2rem';

// Colors
$black: #000000;
$gray: #C4C4C4;
$white: #FFFFFF;

$primary-color: #EE6F48;

$secondary-color: #394899;
$secondary-color-light: #CBD2EE;
$secondary-color-lighter: #F5F6FA;

$text-color: $black;
$text-color-light: #767676;
$placeholder-color: #B3B8CC;
$label-color: #A4B1E4;

$success-color: #01943E;

// Fonts

$font-family: proxima-nova, Sans-Serif;
$font-family-weights: '400,600,700,800';

// Font sizes
$fs: (
    100: rem(12),
    200: rem(14),
    300: rem(16),
    400: rem(18),
    500: rem(20),
    600: rem(24),
    700: rem(28),
    800: '',
    900: '',
);

// Font weights
$fw: (
    100: '',
    200: '',
    300: '',
    400: 400,
    500: '',
    600: 600,
    700: 700,
    800: 800,
    900: ''
);

// Heights
$header-height: 5rem;
$header-height-lg: rem(100);

// Bootstrap
$enable-negative-margins: true;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px
);

//$container-max-widths: (
//    lg: 960px,
//    xl: 1140px,
//    xxl: 1280px
//);
