.header {
    @include z-index(header);
    height: $header-height;

    &:not(&--minimal) {
        position: sticky;
        top: 0;
        background: $white;
    }

    @include media-breakpoint-up(lg) {
        height: $header-height-lg;
    }

    &__logo {
        width: rem(89);
        height: rem(45);

        @include media-breakpoint-up(lg) {
            width: rem(140);
            height: rem(71);
        }
    }

    &__search {
        .input__group {
            height: 3rem;
            padding: rem(15) rem(20);
            border-radius: 1.5rem;
        }
    }

    &__link {
        border-right: 1px solid $secondary-color-light;
    }

    &__nav {
        position: relative;
        display: inline-block;
    }

    &__nav-overlay {
        @include overlay;
        pointer-events: none;
        z-index: 1;
        background: rgba($black, .25);

        @include media-breakpoint-up(lg) {
            .header__nav:hover & {
                @include overlayVisible;
            }
        }

        @include media-breakpoint-down(md) {
            .header__nav[data-open] & {
                @include overlayVisible;
            }
        }
    }

    &__nav-items {
        @include transition;
        position: absolute;
        z-index: 2;
        inset: -1rem -.75rem auto auto;
        width: max-content;
        padding: .5rem .75rem 1rem;
        background: $white;
        border-radius: 1rem;
        box-shadow: 0 0 .5rem rgba($black, .2);
        text-align: right;
        opacity: 0;
        visibility: hidden;

        @include media-breakpoint-up(lg) {
            .header__nav:hover & {
                opacity: 1;
                visibility: visible;
            }
        }

        @include media-breakpoint-down(md) {
            .header__nav[data-open] & {
                opacity: 1;
                visibility: visible;
            }
        }

        a {
            display: block;
            padding: .5rem 0
        }
    }
}
