*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    // sass-lint:disable-all
    font-size: 16px;
    font-family: sans-serif;
    line-height: 1.15; // Correct the line height in all browsers.
    -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
    -ms-text-size-adjust: 100%; // ^^
    -ms-overflow-style: scrollbar; // Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to counteract.
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Change the default tap highlight to be completely transparent in iOS.
}

body {
    overflow-x: hidden;
    text-size-adjust: 100%;
}

// sass-lint:disable single-line-per-selector
h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
}

a {
    color: inherit;
    cursor: pointer;
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

li {
    list-style: none;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="search"] {
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
}

select {
    width: 100%;
}

img,
iframe,
fieldset {
    border: 0;
}

button {
    cursor: pointer;
    border: 0;
    background: none;
    outline: none;
}

svg {
    vertical-align: middle;
}
