.checkbox {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-gap: .75rem;

    * {
        cursor: pointer;
    }

    input[type="checkbox"] {
        display: none;

        &:checked ~ .checkbox__check {
            background: $secondary-color;
        }

        &:disabled ~ * {
            cursor: not-allowed;
            opacity: .5;
        }
    }

    &__check {
        @include transition;
        position: relative;
        width: 1rem;
        height: 1rem;
        border: 2px solid $secondary-color;
        border-radius: .125rem;

        svg {
            position: absolute;
            inset: 50% auto auto 50%;
            width: .75rem;
            height: .75rem;
            transform: translate(-50%, -50%);

            path {
                fill: $white;
            }
        }
    }
}
