.link {
    cursor: pointer;
    display: inline-block;
    color: $secondary-color;
    font-family: $font-family;
    font-size: map-get($fs, 200);
    font-weight: map-get($fw, 600);
    line-height: 1.125rem;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    svg {
        width: rem(14);
        height: rem(14);
        vertical-align: -2px;
    }

    &--primary {
        color: $primary-color;

        svg path {
            fill: $primary-color;
        }
    }

    &--text {
        color: $text-color;

        svg path {
            fill: $text-color;
        }
    }

    &--text-light {
        color: $text-color-light;

        svg path {
            fill: $text-color-light;
        }
    }

    &--fw-400 {
        font-weight: map-get($fw, 400);
    }

    &--fw-700 {
        font-weight: map-get($fw, 700);
    }
}
